<template>
  <div>
    <b-modal
      id="warehouse-output-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      @hidden="clearForm"
      @show="onShow"
    >
      <div class="modal-header">
        <div class="modal-title">Saída de produtos</div>
        <span class="icon-box"><Close class="icon stroke" @click="$bvModal.hide('warehouse-output-modal')"  /></span>
      </div>

      <div class="md-content">
        <div class="row">
          <div class="col-4 padding_0_8_24_0">
            <b-form-group id="field_date">
              <label for="date">
                Data
              </label>
              <date-picker
                class="full"
                v-model="form.date"
                format="DD/MM/YYYY"
                placeholder="DD/MM/AAAA"
                :clearable="false"
                :lang="langDatePicker"
              ></date-picker>
              <div v-if="validated && !form.date" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-form-group>
          </div>

          <div class="col-8 no-padding">
            <b-form-group>
              <label for="type">Tipo de uso do produto</label>
              <multiselect
                id="type"
                v-model="useType"
                track-by="value"
                label="label"
                placeholder="Selecionar"
                :options="useTypes"
                :allow-empty="false"
                :option-height="40"
                :showLabels="false"
                :showNoResults="true"
                class="with-border"
                @select="el => (this.form.type = el.value)"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>

                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.label }}
                </template>

                <template slot="noOptions">
                  Nenhuma opção
                </template>

                <template slot="noResult">
                  Nenhum resultado
                </template>
              </multiselect>

              <div v-if="validated && !form.type" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-form-group>
          </div>
        </div>

        <div v-if="['PROCEDURE', 'SALE', 'COURTESY'].includes(form.type)" class="row">
          <div class="col-6 padding_0_8_16_0">
            <b-form-group>
              <label for="patient">
                Paciente
                <span v-if="['COURTESY'].includes(form.type)" class="help optional">(opcional)</span>
              </label>
              <PatientInput
                :showLabel="false"
                required
                v-model="form.patient"
                :error="validated && !form.patient && !['COURTESY'].includes(form.type)"
                @select="onSelectPatient"
              />
            </b-form-group>
          </div>

          <div class="col-6 no-padding">
            <b-form-group>
              <label for="professional">
                Profissional
                <span v-if="['COURTESY'].includes(form.type)" class="help optional">(opcional)</span>
              </label>
              <multiselect
                id="professional"
                v-model="form.person"
                track-by="id"
                label="name"
                placeholder="Selecionar"
                :options="professionals"
                :searchable="true"
                :allow-empty="false"
                :showLabels="false"
                :showNoResults="true"
                class="with-border"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>

                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.name }}
                </template>

                <template slot="noOptions">
                  <div>Digite para pesquisar seus profissionais...</div>
                </template>

                <template slot="noResult">
                  <div>Nenhum profissional encontrado...</div>
                </template>
              </multiselect>
              <div v-if="validated && !form.person && !['COURTESY'].includes(form.type)" class="custom-invalid-feedback">
                Campo obrigatório
              </div>
            </b-form-group>
          </div>
        </div>

        <div v-if="['PROCEDURE'].includes(form.type)" class="row">
          <div class="col-12 padding_0_0_24">
            <b-form-group>
              <label for="procedures">Procedimentos</label>
              <ProcedureGroupSelect
                v-model="form.procedure"
                :options="appointments"
                placeholder="Selecionar"
                :empty-message="form.patient && !appointments.length ? 'Nenhum procedimento encontrado' : 'Selecione um paciente para ver seus procedimentos aqui'"
                @input="getOutputProductSuggestions"
              />
              <div v-if="validated && !form.procedure" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-form-group>
          </div>
        </div>

        <div v-if="['PROCEDURE', 'SALE', 'COURTESY'].includes(form.type)" class="row">
          <div class="col-6 padding_0_8_16_0">
            <b-form-group>
              <label for="health_plan">
                Convênio
                <span v-if="['COURTESY'].includes(form.type)" class="help optional">(opcional)</span>
              </label>
              <multiselect
                id="health_plan"
                v-model="form.health_plan"
                track-by="id"
                label="fantasy_name"
                placeholder="Selecionar"
                :options="healthPlans"
                :searchable="true"
                :allow-empty="false"
                :option-height="40"
                :showLabels="false"
                :showNoResults="true"
                class="with-border"
                @select="getPlans"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>

                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.fantasy_name }}
                </template>

                <template slot="noOptions">
                  <div>Digite para pesquisar seus convênios...</div>
                </template>

                <template slot="noResult">
                  <div>Nenhum convênio encontrado...</div>
                </template>
              </multiselect>
              <div v-if="validated && !form.health_plan && !['COURTESY'].includes(form.type)" class="custom-invalid-feedback">
                Campo obrigatório
              </div>
            </b-form-group>
          </div>

          <div class="col-6 no-padding">
            <b-form-group>
              <label for="plan">
                Plano
                <span class="help optional">(opcional)</span>
              </label>
              <multiselect
                id="plan"
                v-model="form.plan"
                track-by="id"
                label="name"
                placeholder="Selecionar"
                :options="auxPlans"
                :searchable="true"
                :allow-empty="false"
                :option-height="40"
                :showLabels="false"
                :showNoResults="true"
                :disabled="!auxPlans.length"
                class="with-border"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>

                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.name }}
                </template>

                <template slot="noOptions">
                  <div>Selecione um convênio para pesquisar os planos...</div>
                </template>

                <template slot="noResult">
                  <div>Nenhum plano encontrado...</div>
                </template>
              </multiselect>
              <div v-if="errors.plan" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-form-group>
          </div>
        </div>

        <div v-if="['PROCEDURE', 'SALE', 'COURTESY'].includes(form.type)" class="row">
          <div class="col-12 padding_0_0_24">
            <b-form-group
              id="field_observations"
            >
              <label for="observations">
                Observações
                <span class="help optional">(opcional)</span>
              </label>
              <b-form-input
                autocomplete="off"
                id="observations"
                trim
                placeholder="Adicionar observações"
                v-model="form.observations"
              />
            </b-form-group>
          </div>
        </div>

        <div v-if="['DISCARD', 'CONSUMPTION'].includes(form.type)" class="row">
          <div class="col-12 padding_0_0_24">
            <b-form-group
              id="field_discard_reason"
              :invalid-feedback="'Campo obrigatório'"
              :state="!errors.discard_reason"
            >
              <label for="discard_reason">Motivo</label>
              <b-form-input
                id="discard_reason"
                v-model="form.discard_reason"
                autocomplete="off"
                trim
                :placeholder="`Descrever motivo do ${form.type === 'DISCARD' ? 'descarte' : 'consumo'}`"
              />
              <div v-if="validated && ['DISCARD', 'CONSUMPTION'].includes(form.type) && !form.discard_reason" class="custom-invalid-feedback">
                Campo obrigatório
              </div>
            </b-form-group>
          </div>
        </div>
      </div>

      <div v-if="!form.id && (!form.transactions || !form.transactions.length)">
        <hr/>
        <div class="add-product">
          <b-button
            class="wh-button add-product-button"
            variant="outline-primary"
            size="lg"
            @click="$bvModal.show('warehouse-transaction-entry')"
            :disabled='!this.useType'
          >
            Adicionar produto
          </b-button>
        </div>

        <hr/>
      </div>
      <ProductListTable
        v-else
        :editable="!form.id"
        :useType="form.type"
        :transactions="form.transactions"
        v-on:onAddProduct="$bvModal.show('warehouse-transaction-entry')"
        v-on:onEdit="editProductList"
        v-on:onDelete="removeProduct"
      />

      <div class="md-button">
        <div
          class="custom-invalid-feedback"
            v-if="output?.transactions[0]?.type === 'ENTRANCE' &&
              output?.transactions[0]?.batch_balance &&
              (output?.transactions[0]?.batch_balance?.balance < output?.transactions[0]?.quantity || output?.transactions[0]?.batch_balance < output?.transactions[0]?.quantity)"
            >
          {{ `Apenas ${output?.transactions[0]?.batch_balance?.balance ? output?.transactions[0]?.batch_balance?.balance : output?.transactions[0]?.batch_balance} unidades em estoque, saida total não permitida` }}
        </div>
        <b-button
          class="wh-button"
          variant="primary"
          size="lg"
          @click="form.id ? updateWarehouseOutput() : createWareHouseOutput()"
          :disabled="!form.transactions || !form.transactions.length || output?.transactions[0]?.batch_balance?.balance < output?.transactions[0]?.quantity"
        >
          {{ getActionButtonLabel() }}
        </b-button>
      </div>

      <ProductTransactionModal
        identifier="warehouse-transaction-entry"
        type="OUTPUT"
        :useType="form.type"
        :transaction="transaction"
        v-on:onSave="onModalAddProduct"
        v-on:onUpdate="onModalUpdateProduct"
        v-on:onHide="resetTransaction"
      />

    </b-modal>
  </div>
</template>

<script>
import { track } from '@/utils/mixPanel'
import { EventBus } from '@/utils/eventBus'

export default {
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    ProductTransactionModal: () => import('@/components/Warehouse/ProductTransactionModal'),
    ProductListTable: () => import('@/components/Warehouse/ProductListTable'),
    ProcedureGroupSelect: () => import('@/components/General/ProcedureGroupSelect'),
    PatientInput: () => import('@/components/General/PatientInput'),
  },
  props: {
    output: Object,
    openBillToReceiveProductModal: Function,
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      useType: null,
      form: { date: new Date() },
      validated: false,
      errors: {},
      patients: [],
      professionals: [],
      appointments: [],
      procedures: [],
      healthPlans: [],
      plans: [],
      auxPlans: [],
      transaction: null,
      billToReceive: null,
      langDatePicker: {
        formatLocale: {
	        weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        },
      },
      useTypes: [
        { label: 'Venda', value: 'SALE' },
        { label: 'Descarte', value: 'DISCARD' },
        { label: 'Consumo', value: 'CONSUMPTION' },
        { label: 'Cortesia', value: 'COURTESY' }
      ],
    }
    // { label: 'Uso em procedimentos', value: 'PROCEDURE' },
  },
  methods: {
    async onShow() {
      this.professionals = await this.getProfessionals();
    },
    clearForm() {
      this.validated = false;
      this.form = { date: new Date() };
      this.useType = null;
      this.errors = {};
      this.billToReceive = null;
    },
    getActionButtonLabel() {
      if (this.form.transactions && this.form.transactions.some(transaction => transaction.checked)) {
        return 'Avançar'
      }
      return this.form.id ? 'Atualizar saída' : 'Efetuar saída';
    },
    async onSelectPatient(patient) {
      if (this.form.type === 'PROCEDURE') {
        await this.getAppointmentProcedures(patient.id);
      }
      this.getPatientHealthPlans(patient.id);
    },
    onModalAddProduct(transaction) {
      if (!this.form.transactions) {
        this.form.transactions = [];
      }

      if (this.form.type === 'SALE' && transaction.sale_price) {
        transaction.checked = true;
      }

      const transactions = Object.assign([], this.form.transactions);
      const index = transactions.findIndex(t => t.product.id === transaction.product.id && t.batch === transaction.batch);
      index >= 0 ? transactions[index] = transaction : transactions.push(transaction);
      this.form.transactions = transactions;

      this.$forceUpdate();
    },
    removeProduct(transaction) {
      const index = this.form.transactions.indexOf(transaction);
      this.form.transactions.splice(index, 1);
      this.$forceUpdate();
    },
    onModalUpdateProduct(transaction) {
      this.form.transactions = this.form.transactions.map(t => {
        if (t.id === transaction.id) return transaction;
        return t;
      });
    },
    resetTransaction() {
      this.transaction = null;
    },
    editProductList(transaction) {
      this.transaction = Object.assign({}, transaction);
      this.$bvModal.show('warehouse-transaction-entry');
    },
    getProfessionals() {
      return new Promise((resolve, reject) => {
        this.api.showClinicProfessionals(this.clinic.id)
        .then(res => resolve(res.data.map(res => res)))
        .catch(reject);
      })
    },
    getAppointmentProcedures(patientId) {
      return new Promise((resolve, reject) => {
        this.api.getAppointmentProcedures(this.clinic.id, patientId)
        .then(res => {
          this.appointments = res.data;
          resolve(res.data);
        })
        .catch(reject);
      })
    },
    getPatientHealthPlans(patientId, planId) {
      this.api.getPatientHealthPlans(this.clinic.id, patientId)
      .then(res => {
        this.healthPlans = res.data.map(data => data.health_plan);
        this.plans = res.data.filter(data => data.plan_id).map(data => data.plan);
        this.form.health_plan = this.healthPlans.length ? this.healthPlans[0] : null;
        this.getPlans(this.form.health_plan)
      })
      .catch(err => this.$toast.error(err.message));
    },
    getPlans(healthPlan) {
      this.auxPlans = healthPlan ? this.plans.filter(plan => plan.health_plan_id === healthPlan.id) : null;
      this.form = { ...this.form, plan: null };
    },
    getPayloadProps() {
      const props = {
        clinic_id: this.clinic.id,
        date: this.form.date,
        type: this.form.type,
        discard_reason: this.form.discard_reason,
        observations: this.form.observations,
        transactions: this.form.transactions
      };

      if (['PROCEDURE', 'SALE', 'COURTESY'].includes(this.form.type)) {
        props.patient_id = this.form.patient?.id;
        props.person_id = this.form.person?.id;
        props.health_plan_id = this.form.health_plan?.id;
        props.plan_id = this.form.plan?.id ?? null;

        if (this.form.type === 'PROCEDURE') {
          props.procedure_id = this.form.procedure.id;
        }
      }

      return props;
    },
    async createWareHouseOutput() {
      if (!this.isValidForm()) return;

      const props = this.getPayloadProps();
      const isLoading = this.$loading.show();

      try {
        track('saida_de_produtos', {
          clinic: this.clinic.name,
        });
        if (this.form.transactions && this.form.transactions.some(transaction => transaction.checked)) {
          this.showBillToReceiveProductModal();
          isLoading.hide();
        }
        else{
          await this.api.createWareHouseOutput(props);
          isLoading.hide();
          EventBus.$emit('reloadProductOutputs');
          EventBus.$emit('reloadProducts');
          EventBus.$emit('reloadBatches');
          this.$toast.success('Saída realizada com sucesso!');
    
          this.clearForm();
          this.$bvModal.hide('warehouse-output-modal');
          this.$emit('onHide');
        }
      } catch(ex) {
        this.$toast.error(ex.message)
        isLoading.hide();
      }
    },
    async updateWarehouseOutput() {
      if (!this.isValidForm()) return;
      const isLoading = this.$loading.show();

      const props = {
        clinic_id: this.clinic.id,
        date: this.form.date,
        type: this.form.type,
        discard_reason: this.form.discard_reason,
        observations: this.form.observations,
        transactions: this.form.transactions
      };

      if (['PROCEDURE', 'SALE', 'COURTESY'].includes(this.form.type)) {
        props.patient_id = this.form.patient?.id;
        props.person_id = this.form.person?.id;
        props.health_plan_id = this.form.health_plan?.id;
        props.plan_id = this.form.plan?.id;

        if (this.form.type === 'PROCEDURE') {
          props.procedure_id = this.form.procedure.id;
        }
      }

      try {
        await this.api.updateWarehouseOutput(this.form.id, props)
        isLoading.hide();
        EventBus.$emit('reloadProductOutputs');
        EventBus.$emit('reloadProducts');

        this.clearForm();

        this.$toast.success('Saída atualizada com sucesso!');
        this.$bvModal.hide('warehouse-output-modal');

        this.$emit('onHide');
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }
    },
    getOutputProductSuggestions(clinicProcedure) {
      this.api.getOutputProductSuggestions(clinicProcedure.id)
        .then(res => {
          this.form = {
            ...this.form,
            quantity: res.data.quantity,
            derivatives: res.data.derivatives,
            transactions: res.data.transactions
          }
        })
        .catch(err => this.$toast.error(err.message));
    },
    isValidForm() {
      this.validated = true
      return this.form.date &&
        this.form.type &&
        (!['PROCEDURE', 'SALE'].includes(this.form.type) ||  this.form.patient) &&
        (!['PROCEDURE', 'SALE'].includes(this.form.type) ||  this.form.person) &&
        (!['PROCEDURE', 'SALE'].includes(this.form.type) ||  this.form.health_plan) &&
        (!['PROCEDURE'].includes(this.form.type) || this.form.procedure) &&
        (!['DISCARD', 'CONSUMPTION'].includes(this.form.type) || this.form.discard_reason)
    },
    showBillToReceiveProductModal() {
      const props = this.getPayloadProps();
      const billToReceive = {
        patient: this.form.patient,
        person: this.form.person,
        health_plan: this.form.health_plan,
        plan: this.form.plan,
        transactions: this.form.transactions || [],
        itemProps: props
      };
      this.openBillToReceiveProductModal(billToReceive);
    },
    onHideBillToReceiveProductModal() {
      this.$bvModal.hide('warehouse-output-modal');
    }
  },
  watch: {
    output: async function(newValue) {
        if (newValue) {
          this.useType = this.useTypes.find(el => el.value === newValue.type);
          this.form = {
            ...newValue,
            date: newValue.date ? new Date(newValue.date) : new Date(),
          };
          if (newValue.type === 'PROCEDURE') {
            await this.getAppointmentProcedures(newValue.patient_id);
            this.form = {
              ...this.form,
              procedure: { ...newValue.clinic_procedure }
            }
          }
        } else {
          this.clearForm();
        }
      },
    }
}
</script>

<style lang="scss">
#warehouse-output-modal {
  .modal-content {
    max-width: 750px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);

    .modal-body {
      padding: 0;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        .icon {
          height: 24px;
          width: 24px;
          fill: var(--neutral-500);
        }
      }

      .md-content {
        padding: 24px 24px 0 24px;

        .row {
          margin: 0;
          padding: 0;

          .padding_0_0_24 {
            padding: 0 0 24px;
          }

          .padding_0_8_24_0 {
            padding: 0 8px 24px 0;
          }

          .padding_0_8_16_0 {
            padding: 0 8px 16px 0;
          }

          .padding_0_0_16 {
            padding: 0 0 16px;
          }

          .no-padding {
            padding: 0;
          }

          .title {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: var(--dark-blue);
          }

          .form-group {
            margin: 0 !important;
            padding: 0 !important;
          }

          .optional {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 130%;
            color: var(--type-placeholder);
          }
        }
      }

      .add-product {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .add-product-button {
          margin: 24px 0;
          padding: 12px 38px;
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
        }
      }

      .md-button {
        text-align: right;
        margin: 24px 24px 24px 0;

        button {
          padding: 8px 16px;
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: var(--neutral-000);
        }
      }

      .procedure-prefix {
        display: inline-flex;
        align-items: center;
        color: var(--blue-500);
      }

      .procedure-pipe {
        height: 15px;
        width: 2px;
        background-color: var(--blue-500);
        margin: 0 5px;
        border-radius: 50%;
      }

      .procedure-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        fill: var(--blue-500);
      }

      .information-procedures-wrapper {
        width: 100%;
        display: inline-flex;
        align-items: center;
        padding: 16px 24px;
        background-color: #E5F9FF;
        border-radius: 8px;
        color: var(--type-active);
        margin-bottom: 20px;

        .icon-info {
          width: 42px;
          height: 42px;
          stroke: #0088B2;
          margin-right: 26px;
        }

        h3 {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
        }

        p {
          font-style: normal;
          font-size: 16px;
        }
      }

      hr {
        margin: 0 24px !important;
        padding: 0 !important;
      }

      .table-container {
        margin: 0 !important;
        padding: 0 24px;

        .table {
          margin: 0 !important;
        }
      }
    }
  }
}

#field_date {
  padding: 0 !important;
  margin: 0 !important;
}
</style>

